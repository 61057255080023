/** @jsx jsx */
// import React from 'react'
import { jsx } from 'theme-ui'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import GoogleCalendar from '../../components/GoogleCalendar'

const PinkFloorPage = () => {
  return (
    <Layout>
      <SEO title="Pink Floor Calendar" />
      <GoogleCalendar />
    </Layout>
  )
}

export default PinkFloorPage
