import React from 'react'

const GoogleCalendar = () => {
  return (
    <div style={styles.container}>
      <iframe
        src="https://calendar.google.com/calendar/b/3/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FNew_York&amp;src=dGFpY2hpdXNhLmNvbV9tOHNxa25vY3RiMTFmMzIwcHZxYjc4aTd1a0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;src=ZW4udXNhI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%23cc007e&amp;color=%230B8043&amp;showPrint=0&amp;showCalendars=0&amp;mode=WEEK"
        style={styles.calendar}
        width="800"
        height="600"
        frameborder="0"
        scrolling="no"
        title="Pink Floor Calendar"
      ></iframe>
    </div>
  )
}

const styles = {
  calendar: {
    borderWidth: 0,
  },
  container: {
    maxWidth: 'container',
    margin: '0 auto',
  },
}

export default GoogleCalendar
